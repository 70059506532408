import React, { useEffect } from 'react'
import { navigate } from 'gatsby'

// Components
import SEO from '../../components/SEO'

function Sets() {
  const metaData = {
    title: 'Categories',
    image: undefined
  }

  useEffect(() => {
    navigate('/categories')
  })

  return (
    <div>
      <SEO metaData={metaData} />
    </div>
  )
}

export default Sets
